export const category = [
  {
    name: 'None',
    id: 1,
    value: '',
    description: '',
    children: [],
  },
  {
    name: 'python',
    id: 2,
    value: 'Python',
    description:
      'Pythonはインデントを用いることでコードが読みやすい、プログラミング言語です。型付けにも対応しており、バージョン2系とバージョン3系が使用されています。プログラミングを始めたばかりの方へおすすめです。',
    children: [
      {
        name: 'tkinter',
        value: 'Tkinter',
        description:
          'TkinterはPythonからGUIを構築・操作するための標準ライブラリです。「ティーキンター」や「ティーケーインター」と呼ばれたりします。',
      },
      {
        name: 'opencv',
        value: 'OpenCV',
        description:
          'OpenCVは画像処理・画像解析等の機能をPythonで利用するために必要なライブラリです。1999年にインテルが開発・公開したオープンソースになります。',
      },
    ],
  },
  {
    name: 'zapier',
    id: 3,
    value: 'Zapier',
    description:
      'Zapierを使うと、2000種類以上のサービスを組み合わせて、自分独自のアプリを作成できます。特定の動作に応じて作成したアプリが作動し、日々の作業を劇的に効率化できます。',
    children: [],
  },
  {
    name: 'atcoder',
    id: 4,
    value: 'AtCoder',
    description:
      'AtCoderは、オンラインで参加できるプログラミングコンテスト(競技プログラミング)です。リアルタイムのコンテストで競い合ったり、約3000問のコンテストの過去問に、いつでも挑戦出来ることが魅力です。',
    children: [],
  },
]
