export const getResizeImgUrl = (url: string, size: string): string => {
  const res = url.match(/^(.+)(\.jpg|\.jpeg|\.gif|\.png|\.bmp)(.+)/)
  if (res) {
    return (res[1] + size + res[2] + res[3]).replace('thumbnails', 'resized_thumbnails')
  } else {
    return url
  }
}

export const getResizeArticleUrl = (url: string, size: string): string => {
  const res = url.match(/^(.+\/images)(.+)(\?.+)/)
  if (res) {
    return res[1] + res[2] + '_' + size + res[3]
  } else {
    return url
  }
}

export const timestampToTime = (timestamp: number): string => {
  const date = new Date(timestamp * 1000)
  const yyyy = `${date.getFullYear()}`
  // .slice(-2)で文字列中の末尾の2文字を取得する
  // `0${date.getHoge()}`.slice(-2) と書くことで０埋めをする
  const MM = `0${date.getMonth() + 1}`.slice(-2) // getMonth()の返り値は0が基点
  const dd = `0${date.getDate()}`.slice(-2)

  return `${yyyy}-${MM}-${dd}`
}

export const convertDesc = (text: string, maxTextLength: number): string => {
  return text.length > maxTextLength ? text.slice(0, maxTextLength) + '...' : text
}

export const timestampIOSString = (timestamp: string): string => {
  return new Date(Number(timestamp)).toISOString()
}

/**
 * Get the URL parameter value
 *
 * 参考 : https://www-creators.com/archives/4463
 *
 * @param name {string} パラメータのキー文字列
 * @return url {url} 対象のURL文字列
 */
export const getParam = (name: string, url: string): string | null => {
  name = name.replace('/[[]]/g', '\\$&')
  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url)
  if (!results) return ''
  if (!results[2]) return ''
  return decodeURIComponent(results[2].replace(/\+/g, ' '))
}

/**
 * Get estimate time for article.
 *
 * @param article {string} 記事
 * @return number {number} 記事の読了目安時間
 */
export const getEstimatedTime = (article: string): number => {
  const readTextNumPerSeconds = 10
  return Math.floor(article.length / readTextNumPerSeconds / 60)
}
