import React from 'react'
import { category } from '../../config/category'
import LazyLoad from 'react-lazyload'

const Footer = (): JSX.Element => {
  const disCategory = category.filter((cate) => cate.name !== 'None')
  const now = new Date()

  return (
    <footer className="footer__footer">
      <div className="footer__flexBox">
        <nav className="footer__nav">
          <p className="footer__p">記事を読む</p>
          <ul className="footer__ul">
            {disCategory &&
              disCategory.map(
                (
                  category: {
                    name: string
                    id: number
                    value: string
                    children: Array<{
                      name: string
                      value: string
                    }>
                  },
                  idx: number
                ) => (
                  <>
                    <li className="footer__li" key={idx}>
                      <a href={'/' + category.name + '/'} className="footer__a">
                        {category.value}
                      </a>
                    </li>
                    {category.children.length > 0 &&
                      category.children.map((child: { name: string; value: string }) => {
                        return (
                          <li key={idx} className="footer__li">
                            <a href={'/' + child.name + '/'} className="footer__a">
                              {child.value}
                            </a>
                          </li>
                        )
                      })}
                  </>
                )
              )}
          </ul>
        </nav>
        <nav className="footer__nav">
          <p className="footer__p">KURORO BLOGについて</p>
          <ul className="footer__ul">
            <li className="footer__li">
              <a href={'/smap/'} className="footer__a">
                サイトマップ
              </a>
            </li>
            <li className="footer__li">
              <a href={'/disclaimer/'} className="footer__a">
                免責事項
              </a>
            </li>
            <li className="footer__li">
              <a href={'/privacy/'} className="footer__a">
                プライバシーポリシー
              </a>
            </li>
            <li className="footer__li">
              <a href={'/agreement/'} className="footer__a">
                利用規約
              </a>
            </li>
            <li className="footer__li">
              <a href={'/contacts/'} className="footer__a">
                お問い合わせ一覧
              </a>
            </li>
            <li className="footer__li">
              <a href={'/profile/'} className="footer__a">
                プロフィール詳細
              </a>
            </li>
            <li className="footer__li">
              <a href={'/company/'} className="footer__a">
                会社概要
              </a>
            </li>
            <li className="footer__li">
              <a href={'/mutual-link/'} className="footer__a">
                おすすめリンク集
              </a>
            </li>
            <li className="footer__li">
              <a
                href={'https://twitter.com/KURORO59126227'}
                className="footer__a"
                target="_blank"
                rel="noopener noreferrer"
              >
                Twitter
              </a>
            </li>
            <li className="footer__li">
              <a
                href="https://www.wantedly.com/id/kurokuroro"
                rel="noopener noreferrer"
                className="footer__a"
                target="_blank"
              >
                Wantedly
              </a>
            </li>
            <li className="footer__li">
              <a href="https://github.com/kuroroblog" rel="noopener noreferrer" className="footer__a" target="_blank">
                GitHub
              </a>
            </li>
            <li className="footer__li">
              <a href={'/rss.xml'} className="footer__a">
                RSS
              </a>
            </li>
          </ul>
        </nav>
      </div>

      <div className="footer__footerSymbol">
        <a href="/">
          <LazyLoad>
            <img
              src="https://firebasestorage.googleapis.com/v0/b/kuro-site.appspot.com/o/images%2Fheader_logo.png?alt=media&token=08679d84-12bf-4d77-aeb4-bac7f3f40f8d"
              width={150}
              height={150}
              alt="KURORO BLOGのロゴ"
              referrerPolicy="no-referrer"
              className="footer__img"
            />
          </LazyLoad>
          <noscript>
            <style>{`.lazyload-placeholder { display: none; }`}</style>
            <img
              src="https://firebasestorage.googleapis.com/v0/b/kuro-site.appspot.com/o/images%2Fheader_logo.png?alt=media&token=08679d84-12bf-4d77-aeb4-bac7f3f40f8d"
              width={150}
              height={150}
              alt="KURORO BLOGのロゴ"
              referrerPolicy="no-referrer"
              className="footer__img"
            />
          </noscript>
        </a>
        <p className="footer__copyRight">{'Copyright ' + now.getFullYear() + ' KURORO BLOG.'}</p>
      </div>
    </footer>
  )
}
export default Footer
