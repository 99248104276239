import React from 'react'
import { Helmet } from 'react-helmet'
import { graphql, useStaticQuery } from 'gatsby'
import { timestampIOSString, getResizeImgUrl } from '../util/format'

const Seo = ({
  title,
  description,
  url,
  imagePath,
  contentType,
  article,
  imgList,
  noIndex = true,
  isProfilePage = false,
  isTopPage = false,
}: any): JSX.Element => {
  const { site } = useStaticQuery(query)
  const { defaultTitle, defaultDescription, defaultUrl, defaultContentType, defaultImagePath } = site.siteMetadata

  const seo = {
    title: title ? `${title} | ${defaultTitle}` : defaultTitle,
    description: description || defaultDescription,
    url: url || defaultUrl,
    imagePath: imagePath || defaultImagePath,
    contentType: contentType || defaultContentType,
    imgList: imgList || [],
    noIndex: noIndex,
    isProfilePage: isProfilePage,
    isTopPage: isTopPage,
  }

  return (
    <Helmet>
      <html lang="ja" prefix="og: https://ogp.me/ns# http://ogp.me/ns/article#" />
      <meta charSet="utf-8" />
      <title>{seo.title}</title>
      {seo.description !== defaultDescription && <meta name="description" content={seo.description} />}
      {seo.url !== defaultUrl && <link href={seo.url} rel="canonical" />}
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      {seo.description !== defaultDescription && seo.url !== defaultUrl && (
        <meta name="twitter:card" content="summary" />
      )}
      {seo.description !== defaultDescription && seo.url !== defaultUrl && (
        <meta name="twitter:site" content="@KURORO59126227" />
      )}
      {seo.description !== defaultDescription && seo.url !== defaultUrl && (
        <meta property="og:site_name" content="KURORO BLOG" />
      )}
      {seo.description !== defaultDescription && seo.url !== defaultUrl && (
        <meta property="og:type" content={seo.contentType} />
      )}
      {seo.description !== defaultDescription && seo.url !== defaultUrl && (
        <meta property="og:locale" content="ja_JP" />
      )}
      {seo.description !== defaultDescription && seo.url !== defaultUrl && <meta property="og:url" content={seo.url} />}
      {seo.description !== defaultDescription && seo.url !== defaultUrl && (
        <meta property="og:title" content={seo.title} />
      )}
      {seo.description !== defaultDescription && seo.url !== defaultUrl && (
        <meta property="og:description" content={seo.description} />
      )}
      {seo.description !== defaultDescription && seo.url !== defaultUrl && (
        <meta property="og:image" content={seo.imagePath} />
      )}

      <meta name="robots" content="max-image-preview:large" />

      <link rel="preconnect dns-prefetch" href="platform.twitter.com" />
      <link rel="preconnect dns-prefetch" href="https://cdnjs.cloudflare.com" />
      <link rel="preconnect dns-prefetch" href="https://adservice.google.co.jp" />
      <link rel="preconnect dns-prefetch" href="https://adservice.google.com" />
      <link rel="preconnect dns-prefetch" href="https://firebasestorage.googleapis.com" />
      <link rel="preconnect dns-prefetch" href="https://fonts.googleapis.com" />
      <link rel="preconnect dns-prefetch" href="https://googleads.g.doubleclick.net" />
      <link rel="preconnect dns-prefetch" href="https://pagead2.googlesyndication.com" />
      <link rel="preconnect dns-prefetch" href="https://www.clarity.ms" />

      <link rel="preconnect dns-prefetch" href="https://www.googletagservices.com" />
      <link rel="preconnect dns-prefetch" href="https://partner.googleadservices.com" />
      <link rel="preconnect dns-prefetch" href="https://b.st-hatena.com" />
      <link rel="preconnect dns-prefetch" href="https://www.googletagmanager.com" />
      <link rel="preconnect dns-prefetch" href="https://tpc.googlesyndication.com" />
      <link rel="preconnect dns-prefetch" href="https://widget.intercom.io" />

      <link rel="preconnect dns-prefetch" href="https://twitter.com" />
      <link rel="preconnect dns-prefetch" href="https://www.facebook.com" />
      <link rel="preconnect dns-prefetch" href="https://github.com" />
      <link rel="preconnect dns-prefetch" href="https://www.wantedly.com" />
      <link rel="preconnect dns-prefetch" href="https://webtan.impress.co.jp" />
      <link rel="preconnect dns-prefetch" href="https://jp.techcrunch.com" />
      <link rel="preconnect dns-prefetch" href="https://adssettings.google.com" />
      <link rel="preconnect dns-prefetch" href="https://adclick.g.doubleclick.net" />

      {seo.isTopPage && (
        <link
          rel="alternate"
          type="application/rss+xml"
          title="KURORO BLOG | 「モノづくりから始まるエンジニア」のフィード"
          href="https://kuroro.blog/rss.xml"
        />
      )}

      {seo.noIndex == true && <meta name="robots" content="noindex,nofollow" />}
      {seo.imgList.length > 0 &&
        seo.imgList.map((img: string, idx: number) => <link key={idx} rel="preload" as="image" href={img} />)}
      {seo.contentType === 'article' && article && (
        <script async type="application/ld+json">
          {JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'Article',
            headline: article.title,
            image: [getResizeImgUrl(article.thumbnail, '1380x920')],
            datePublished: timestampIOSString(String(article.createdAt.seconds) + '000'),
            dateModified: timestampIOSString(String(article.updatedAt.seconds) + '000'),
            author: {
              '@type': 'Person',
              name: '黒光俊秀',
              jobTitle: 'KURORO BLOG 運営者',
              url: 'https://kuroro.blog/profile/',
              sameAs: [
                'https://github.com/kuroroblog',
                'https://twitter.com/KURORO59126227',
                'https://www.wantedly.com/id/kurokuroro',
                'https://atcoder.jp/users/kuroroblog',
              ],
            },
          })}
        </script>
      )}
      {seo.isProfilePage && (
        <script async type="application/ld+json">
          {JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'Person',
            address: {
              '@type': 'PostalAddress',
              addressLocality: 'Minato-ku',
              addressRegion: 'Tokyo',
              postalCode: '105-0014',
              streetAddress: '1-10-12',
            },
            birthDate: '1994-12-07',
            birthPlace: '愛媛県',
            email: 'arimagedon21@gmail.com',
            gender: 'male',
            givenName: '俊秀',
            height: '175cm',
            homeLocation: '東京都',
            jobTitle: 'KURORO BLOG 運営者',
            weight: '50kg',
            alternateName: ['KURORO', 'kuroro', '黒光俊秀'],
            name: 'Toshihide Kuromitsu',
            url: 'https://kuroro.blog/',
            sameAs: [
              'https://github.com/kuroroblog',
              'https://twitter.com/KURORO59126227',
              'https://www.wantedly.com/id/kurokuroro',
              'https://atcoder.jp/users/kuroroblog',
            ],
            image:
              'https://firebasestorage.googleapis.com/v0/b/kuro-site.appspot.com/o/thumbnails%2Fself-image.webp?alt=media&token=620a5e85-cd7d-4157-9820-e21b8cf9f613',
          })}
        </script>
      )}
      <script type="application/ld+json">
        {JSON.stringify({
          '@context': 'https://schema.org',
          '@type': 'Organization',
          url: 'https://kuroro.blog/',
          logo:
            'https://firebasestorage.googleapis.com/v0/b/kuro-site.appspot.com/o/images%2Flogo.png?alt=media&amp;token=0cf05c92-05ce-436f-9fc0-d3c43d0d1bcc',
        })}
      </script>

      {seo.isTopPage && (
        <script async type="application/ld+json">
          {JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'WebSite',
            url: 'https://kuroro.blog/',
            potentialAction: {
              '@type': 'SearchAction',
              target: {
                '@type': 'EntryPoint',
                urlTemplate: 'https://kuroro.blog/search/?q={search_term_string}',
              },
              'query-input': 'required name=search_term_string',
            },
          })}
        </script>
      )}
    </Helmet>
  )
}

export default Seo

const query = graphql`
  query {
    site {
      siteMetadata {
        defaultTitle: title
        defaultDescription: description
        defaultUrl: url
        defaultImagePath: imagePath
        defaultContentType: contentType
      }
    }
  }
`
